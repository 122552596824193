<template>
  <Aboutme />
</template>

<script>
import Aboutme from "../components/aboutme";

export default {
    components: {
        Aboutme
    }
}
</script>

<style>

</style>